.navbar {
    border-right: 1px solid #ccc;
    min-height: 80vh;
  
    .user {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 4rem;
      background-color: var(--light-blue);
  
      h4 {
        color: #fff;
      }
    }
  
    nav ul {
      li {
        border-bottom: 1px solid #ccc;
        padding: 1rem;
        position: relative;
        a {
          display: block;
          width: 100%;
        }
      }
    }
  }
  
  .active {
    cursor: pointer;
  }
  
  .active::before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 4px;
    height: 100%;
    background-color: orangered;
  }