.product-list {
    width: 100%;
    .top {
      width: 100%;
      border-bottom: 2px solid #ccc;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // flex-wrap: wrap;
      .icons {
        display: flex;
        justify-content: center;
        align-items: center;
        & > * {
          margin-right: 7px;
          cursor: pointer;
        }
      }
  
      .sort {
        label {
          font-size: 1.4rem;
          font-weight: 500;
          margin: 0 5px;
        }
        select {
          font-size: 1.6rem;
          font-weight: 300;
          border: none;
          // border-bottom: 1px solid #777;
          outline: none;
        }
      }
    }
  
    // .search {
    //   margin: 5px 0;
    //   position: relative;
    //   flex: 1;
  
    //   .icon {
    //     position: absolute;
    //     top: 50%;
    //     left: 1rem;
    //     transform: translateY(-50%);
    //   }
  
    //   input[type="text"] {
    //     display: block;
    //     font-size: 1.6rem;
    //     font-weight: 300;
    //     padding: 1rem;
    //     padding-left: 3rem;
    //     margin: 1rem auto;
    //     width: 100%;
    //     border: 1px solid #777;
    //     border-radius: 3px;
    //     outline: none;
    //   }
    // }
    @media screen and (max-width: 800px) {
      .top {
        flex-direction: column;
        align-items: start;
        padding-bottom: 5px;
      }
      .search {
        width: 100%;
      }
    }
  }
  
  .grid {
    display: flex;
    justify-content: space-around;
  
    flex-wrap: wrap;
    // padding: 1rem 0;
    background-color: #fffdf7;
  }